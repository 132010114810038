import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Header from "../../../../../Components/Header";
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import { CurrencyText, DateText } from "../../../../../Utils/Template";

export default props => {

  const [loan, setLoan] = useState({ schedule: [], stats: [] });
  const [selectedPoints, setSelectedPoints] = useState(0);

  useEffect(() => {
    fetchLoan();
  }, []);

  const fetchLoan = async () => {
    let body = { loanNumber: params.loanNumber };
    const response = await Http.request({ uri: `/loans/get`, body });
    if (response.notification) {
      Notifier(response.notification);
    }
    if (response.result === 'success') {
      setLoan(response.body);
      let arrears = response.body.stats.find(item => item.name === 'Arrears');
      if (arrears && arrears.stat > 0) {
        setPaymentAmount(arrears.stat.toFixed(2));
      } else {
        let nextInstalment = response.body.stats.find(item => item.name === 'Next Instalment');
        if (nextInstalment) {
          setPaymentAmount(nextInstalment.stat.toFixed(2));
        }
      }
    }
  }

  const transformStat = (name, value) => {
    if (['Balance', 'Arrears', 'Next Instalment'].includes(name)) return <CurrencyText value={value} />;
    if (['Start Date', 'Maturity Date', 'Next Due Date'].includes(name)) return <DateText value={value} />;
    if (['Status'].includes(name)) return value.toFriendlyCase();
    return value;
  };

  const getPointsOptions = () => {
    // Determine how many increments of 50,000 points the user has.
    const increments = Math.floor(loan.rewardPoints / 50000);
    const pointsOptions = [];

    for (let i = 1; i <= increments; i++) {
      // Make sure the points won't pay more than the loan balance.
      if (i * 500 <= loan.balance) {
        pointsOptions.push(i * 50000);
      }
    }

    // If the loan balance is less than the amount that can be paid with points, add an option for the exact amount.
    if (loan.balance < increments * 500) {
      pointsOptions.push(Math.ceil(loan.balance / 500) * 50000);
    }

    return pointsOptions;
  };

  const submitRedemptionPayment = async () => {
    setPaymentInProgress(true);
    let body = {
      loanNumber: params.loanNumber,
      // rewardPoints: Math.floor(loan.rewardPoints / 100) * 100,
      rewardPoints: +selectedPoints
    };
    const response = await Http.request({ uri: `/loans/redemption/payment`, body });
    if (response.notification) {
      Notifier(response.notification);
    }
    if (response.result === 'success' && !response.notification) {
      Notifier({ text: 'Payment submitted successfully' });
      fetchLoan();
    }
    setPaymentInProgress(false);
  };

  const [paymentFlowInProgress, setPaymentFlowInProgress] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);

  const startCardPaymentFlow = e => {
    e.preventDefault();
    setIsPaymentModalOpen(true);
  };

  const handlePaymentAmountChange = (value) => {
    if (!value) return setPaymentAmount('');
    // Ensure the input is a valid number and not greater than the loan balance
    let amount = parseFloat(value) || 0;
    amount = Math.min(amount, loan.balance);
    if (`${amount}`.indexOf('.') === -1) return setPaymentAmount(amount);
    setPaymentAmount(amount.toFixed(2));
  };

  const processPayment = async () => {
    if (paymentAmount <= 100) return Notifier({ text: 'Payment amount is too small. The minimum payment is $100.00.' });
    
    setPaymentFlowInProgress(true);
    setIsPaymentModalOpen(false);

    let body = {
      loanNumber: params.loanNumber,
      amount: paymentAmount * 1.0505
    };
    const response = await Http.request({ uri: `/loans/payment/intent`, body });
    if (response.notification) {
      Notifier(response.notification);
    }
    if (response.result === 'success' && !response.notification) {
      window.location.replace(response.body.redirectUrl);
    }
  };

  const [paymentInProgress, setPaymentInProgress] = useState(false);

  let params = useParams();
  return (
    <>
      <Header title="Loan Details" />
      <div className="px-10 py-10 sm:px-0">
        {loan.status &&
          <>
            <div className="p-6">
              <div className="sm:flex sm:items-center sm:justify-between">
                <div className="sm:flex sm:space-x-5">
                  <div className="flex-shrink-0">
                    <img className="mx-auto h-20 w-20 rounded-full" src={loan.imageUrl} alt="" />
                  </div>
                  <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                    <p className="text-xl font-bold text-gray-900 sm:text-2xl">{loan.loanProductName}</p>
                    <p className="text-sm font-medium text-gray-600">Loan Number: #{loan.loanNumber}</p>
                  </div>
                </div>
                <div className="mt-5 flex justify-center sm:mt-0">

                  {paymentAmount > 0 && <>
                    <span className="mr-10">
                      <button
                        className="flex items-center rounded-lg overflow-hidden border border-blue-600"
                        onClick={e => startCardPaymentFlow(e)}
                        disabled={paymentFlowInProgress}
                      >
                        <div className="flex items-center px-4 py-2 bg-white" title="There is a 5.05% convenience fee associated with this payment. This fee is charged by the card processing provider to facilitate the transaction.">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-blue-600 mr-2">
                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                          </svg>
                          {/* <span className="text-blue-600">
                            <CurrencyText value={paymentAmount} /> + Fee (<CurrencyText value={paymentAmount * 0.0505} />)
                          </span> */}
                        </div>
                        <div className="px-4 py-2 bg-blue-600 text-white">
                          Pay with Card
                        </div>
                      </button>
                    </span>
                  </>}
                  &nbsp;&nbsp;
                  <a
                    href="/app/loans"
                    className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                  >
                    View all loans
                  </a>
                </div>
              </div>
            </div>
            <div className="h-96 rounded-lg border-4 border-dashed border-gray-200 p-10">
              <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                {loan.stats.map((item) => (
                  <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                    <dt className="truncate text-sm font-medium text-gray-800">{item.name}</dt>
                    <dd className="mt-1 text-3xl tracking-tight text-gray-900">{transformStat(item.name, item.stat)}</dd>
                  </div>
                ))}
              </dl>
            </div>
            <br />
            <div className="w-full">

              {loan.canRedeem && <>
                <div className="bg-green-200 p-15 py-5 sm:px-6 lg:px-8 mt-5 mb-5 mx-auto w-2/3 md:w-1/2 rounded-lg">
                  Your balance on this loan is <CurrencyText value={loan.balance} />.
                  You can pay with {loan.rewardPoints - loan.rewardPoints % 50000} reward points out of your total of {loan.rewardPoints} points.<br />
                  <select
                    value={selectedPoints}
                    onChange={e => setSelectedPoints(e.target.value)}
                    className="py-2 px-3 bg-white text-black mt-5"
                  >
                    <option className="py-2 px-3">Select an option</option>
                    {getPointsOptions().map((points, index) => (
                      <option key={index} value={points} className="py-2 px-3">
                        Pay ${points / 100} with {points} points
                      </option>
                    ))}
                  </select>

                  {selectedPoints > 0 && <><button
                    type="submit"
                    className={`mt-5 flex rounded-md border border-transparent ${!paymentInProgress ? 'bg-indigo-600' : 'bg-indigo-400'} py-2 px-4 text-sm font-medium text-white shadow-sm ${!paymentInProgress ? 'hover:bg-indigo-700' : 'hover:bg-indigo-500'} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                    disabled={paymentInProgress}
                    onClick={() => submitRedemptionPayment()}
                  >
                    {paymentInProgress ? <><i className="fas fa-spinner fa-spin"></i>&nbsp;Please Wait ...</> : 'Pay with Points'}
                  </button></>}
                </div>
              </>}
            </div>
            {/*
            <script type="text/javascript" src="https://api.fygaro.com/api/v1/payments/payment-button/95e99b2f-1b57-42f0-b06b-2e334f18bfab/render/"></script>
            */}
            <div className="bg-white p-15 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto mt-10">
                  <h1 className="text-xl font-semibold text-gray-900">Loan Schedule</h1>
                  <p className="mt-2 text-sm text-gray-700">
                    The table below shows a breakdown of your instalments.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                </div>
              </div>
              <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-3 py-5 text-left text-sm font-semibold text-gray-900">
                              Payment Date
                            </th>
                            <th scope="col" className="px-3 py-5 text-left text-sm font-semibold text-gray-900">
                              Payment Status
                            </th>
                            {/*<th scope="col" className="px-3 py-5 text-left text-sm font-semibold text-gray-900">
                            Amount Expected
                          </th>
                          <th scope="col" className="px-3 py-5 text-left text-sm font-semibold text-gray-900">
                            Penalties
                          </th>*/}
                            <th scope="col" className="px-3 py-5 text-left text-sm font-semibold text-gray-900">
                              Payment Amount
                            </th>
                            <th scope="col" className="px-3 py-5 text-left text-sm font-semibold text-gray-900">
                              Already Paid
                            </th>
                            <th scope="col" className="px-3 py-5 text-left text-sm font-semibold text-gray-900">
                              To be Paid
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {loan.schedule.map((instalment) => (
                            <tr key={instalment.paymentDate}>
                              <td className="whitespace-nowrap px-3 py-5"><DateText date={instalment.paymentDate} />
                              </td>
                              <td className="whitespace-nowrap px-3 py-5">{instalment.status.toFriendlyCase()}</td>
                              {/*<td className="whitespace-nowrap px-3 py-5">{instalment.instalment}</td>
                            <td className="whitespace-nowrap px-3 py-5">{instalment.penaltyCharged}</td>
                            */}
                              <td className="whitespace-nowrap px-3 py-5"><CurrencyText
                                value={instalment.totalCharged} /></td>
                              <td className="whitespace-nowrap px-3 py-5"><CurrencyText
                                value={instalment.totalPaid} /></td>
                              <td className="whitespace-nowrap px-3 py-5"><CurrencyText
                                value={instalment.totalBalance} /></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        {!loan.status &&
          <>

          </>
        }
      </div>

      {isPaymentModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-2xl font-semibold leading-6 text-gray-900 mb-8">
                      Confirm Payment
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 mb-8">
                        Make a payment using your Visa or Mastercard. There is a 5.05% convenience fee associated with this payment. This fee is charged by the card processing provider to facilitate the transaction.
                      </p>

                      <div className="space-y-4 text-left">
                        <div className="flex flex-col mt-1 mb-4">
                          <label className="text-sm font-medium text-gray-700 mb-1">
                            Payment Amount (Max: <CurrencyText value={loan.balance} />)
                          </label>
                          <div className="flex items-center">
                            <div className="bg-gray-200 px-4 py-2 rounded-l-md">$</div>
                            <input
                              type="number"
                              value={paymentAmount}
                              onChange={(e) => handlePaymentAmountChange(e.target.value)}
                              step="0.01"
                              className="w-full rounded-r-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-4 py-2"
                            />
                          </div>
                        </div>

                        <div className="flex justify-between text-lg">
                          <span>Loan Payment:</span>
                          <span><CurrencyText value={paymentAmount} /></span>
                        </div>
                        <div className="flex justify-between text-lg">
                          <span>Transaction Fee (5.05%):</span>
                          <span><CurrencyText value={paymentAmount * 0.0505} /></span>
                        </div>
                        <div className="border-t pt-4 flex justify-between text-lg font-bold">
                          <span>Total:</span>
                          <span><CurrencyText value={paymentAmount * 1.0505} /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-8 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={processPayment}
                    disabled={paymentAmount <= 0}
                  >
                    Continue to Pay
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setIsPaymentModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
